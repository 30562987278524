import Typography from "typography";
import fairyGatesTheme from "typography-theme-fairy-gates";
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants";

import "~/styles/global.css";

fairyGatesTheme.overrideThemeStyles = ({ rhythm }) => {
  return {
    [MOBILE_MEDIA_QUERY]: {
      blockquote: {
        borderLeft: `${rhythm(3 / 16)} solid var(--textLink)`,
        color: "var(--textQuote)",
        paddingLeft: rhythm(9 / 16),
        fontStyle: "italic",
        marginLeft: 0,
        marginRight: 0,
      },
    },
  };
};
const typography = new Typography(fairyGatesTheme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
