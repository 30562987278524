import axios from "axios";
import { SendEmailProps } from "~/@types";

const sendEmail = async ({ name, email, body }: SendEmailProps) => {
  const url = `https://getform.io/f/9e05121c-12a9-4a8c-a205-ec8162c25416`;
  const data = await axios.post(url, { body, name, email });

  return data;
};

export default sendEmail;
