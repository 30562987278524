import React from "react";
import {
  Switch as MuiSwitch,
  Theme,
  makeStyles,
  SwitchProps,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = props =>
  makeStyles((theme: Theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      color: props.labelColor,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#333",
          opacity: 1,
          border: "1px solid #fff",
        },
      },
      "&$focusVisible $thumb": {
        border: "6px solid #fff",
      },
    },
    formLabel: {
      color: props.labelColor,
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${props.labelColor}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }));

type Props = SwitchProps & {
  label?: string;
  labelColor?: string;
};

const Switch: React.FC<Props> = ({ classes, label, labelColor, ...props }) => {
  const {
    root,
    switchBase,
    thumb,
    track,
    checked,
    focusVisible,
    formLabel,
  } = useStyles({
    labelColor,
  })();
  return (
    <FormControlLabel
      value="start"
      classes={{
        root: formLabel,
      }}
      control={
        <MuiSwitch
          focusVisibleClassName={focusVisible}
          disableRipple
          classes={{
            root: root,
            switchBase: switchBase,
            thumb: thumb,
            track: track,
            checked: checked,
          }}
          {...props}
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};

export default Switch;
