import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import { DarkModeToggle } from "~/components/DarkModeToggle";

const Wrapper = styled.header({
  marginBottom: "2rem",
});

const Top = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
});

const Contact = styled.div({});

type HeaderProps = {
  title: string;
};

const Header: React.FC<HeaderProps> = ({ title, children }) => {
  return (
    <Wrapper>
      <Top>
        <h3
          style={{
            marginTop: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
        <DarkModeToggle />
      </Top>
      <Contact>
        <Link to={"/"}>Home</Link> | <Link to={"/contact"}>Contact</Link>
      </Contact>
    </Wrapper>
  );
};

export default Header;
