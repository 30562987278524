import React from "react";

import { rhythm, scale } from "../../utils/typography";
import { LayoutWrapper } from "~/components/LayoutWrapper";
import { Header } from "~/components/Header";

interface Props {
  location: Location;
  title: string;
  children?: any;
}

const Layout = ({ location, title, children }: Props) => (
  <LayoutWrapper>
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(30),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        color: "var(--textNormal)",
        transition: "color 0.2s ease-out, background 0.2s ease-out",
      }}
    >
      <Header title={title} />
      <main>{children}</main>
      <footer>© {new Date().getFullYear()}</footer>
    </div>
  </LayoutWrapper>
);

export default Layout;
