import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../../utils/typography";
import { Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from "@material-ui/core/styles";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";

import { SendEmailProps } from "~/@types";
import { sendEmail } from "~/api/contact";

const FormWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: rhythm(16),
  padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
  "& form": {
    width: "100%",
  },
});

const InputWrapper = styled.div({
  marginBottom: `${rhythm(0.75)}`,
  "& label": {
    display: "block",
    marginBottom: `${rhythm(0.25)}`,
  },
  "& input, & textarea": {
    minWidth: "100%",
    borderRadius: 4,
    padding: 8,
    border: "1px solid var(--textLink)",
  },
});

const ThankYou = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2.5rem",
});

type InputProps = {
  name: string;
  label: string;
  register: any;
  required: boolean;
  rows?: number;
};

const Input = ({ label, name, register, required, rows }: InputProps) => (
  <InputWrapper>
    <label>{label}</label>
    {rows ? (
      <textarea name={name} ref={register({ required })} rows={rows} />
    ) : (
      <input name={name} ref={register({ required })} />
    )}
  </InputWrapper>
);

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

const ContactForm: React.FC<WithStyles<typeof styles>> = () => {
  const [isSent, setIsSent] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { dirty },
  } = useForm<SendEmailProps>();

  const data = useStaticQuery(graphql`
    query ContactInfo {
      site {
        siteMetadata {
          contactEmail
        }
      }
    }
  `);

  const onSubmit = handleSubmit(async (data: SendEmailProps, e) => {
    try {
      if (!data?.password?.length) {
        await sendEmail(data);
      }

      setIsSent(true);
      reset();
    } catch ({ message }) {
      console.error(message);
    }
  });

  return (
    <div>
      <Typography variant="h5" component="h5" align="center" gutterBottom>
        Hello? Is it <br />
        <a href={data.site.siteMetadata.contactEmail}>
          {data.site.siteMetadata.contactEmail}
        </a>
        <br />
        you're lookin' for?
      </Typography>

      <FormWrapper>
        {!isSent ? (
          <form onSubmit={onSubmit}>
            <input
              type="text"
              name="password"
              ref={register}
              style={{ display: "none" }}
              tabIndex={-1}
              autoComplete="off"
            ></input>
            <Input label="Name" name="name" register={register} required />
            <Input label="Email" name="email" register={register} required />
            <Input
              label="Message"
              name="body"
              rows={5}
              register={register}
              required
            />
            <button type="submit" disabled={!dirty}>
              Submit
            </button>
          </form>
        ) : (
          <ThankYou>
            <Typography variant="h6" component="h6" align="center" gutterBottom>
              Thank You!
            </Typography>
            <Typography component="p" align="center">
              I usually respond within 24 hours.
            </Typography>
          </ThankYou>
        )}
      </FormWrapper>
    </div>
  );
};

export default withStyles(styles)(ContactForm);
