import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";

import { ReactComponent as Face } from "~/icons/face-filled.svg";
import { ReactComponent as FaceSunGlasses } from "~/icons/face-sunglasses.svg";
import { Switch } from "~/components/FormComponents";

type ThemeTogglerProps = {
  theme: string;
  toggleTheme: (theme: string) => void;
}
const DarkModeToggle: React.FC = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }:ThemeTogglerProps) => {
      if (theme == null) {
        return null;
      }
      return (
        <Switch
          icon={<FaceSunGlasses fontSize="small" />}
          checkedIcon={<Face fontSize="small" />}
          onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
          checked={theme === "dark"}
          label="mode"
          labelColor={theme === "dark" ? "#fff" : "rgb(2, 139, 213)"}
        />
      );
    }}
  </ThemeToggler>
);

export default DarkModeToggle;
