import React from "react";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

export let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#03A9F4",
    },
    secondary: {
      main: "#03A9F4",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
    divider: "#BDBDBD",
  },

  overrides: {
    MuiTextField: {
      root: {
        marginBottom: "1.5rem",
      },
    },
  },
});
theme = responsiveFontSizes(theme);

const LayoutWrapper: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default LayoutWrapper;
